<template>
  <b-modal
    id="replacedPolicyDetailModal"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-earmark"></b-icon>
        <span>Polizza Sostituente</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <template v-if="missingNewPolicy">
          <p style="font-size: 14px">
            Siamo spiacenti c'è stato un errore. <br />Se il problema persiste,
            contattare l'assistenza.
          </p>
        </template>
        <template v-else>
          <details-card
            v-for="(item, index) in dtlFlds1"
            :key="index"
            :header="item.header"
            :edit="item.edit || false"
            :shortcut="item.shortcut"
            :fields="item.fields"
            :beForm="beForm"
            :data="details"
            :repository="item.repository"
          >
          </details-card>
          <details-card
            v-for="(item, index) in dtlFlds2"
            :key="index"
            :header="item.header"
            :edit="item.edit || false"
            :shortcut="item.shortcut"
            :fields="item.fields"
            :beForm="beForm"
            :data="details"
            :repository="item.repository"
          >
          </details-card>
        </template>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";
import { toInfoData } from "@/utils/transforms";
import { getRiskTypeDescription } from "@/utils/payments";
import { canVerb } from "@/utils/permissions";
export default {
  name: "replacedPolicyDetail",
  mixins: [FormMixin, ShowMixin, ShortcutMixin],
  data() {
    return {
      details: null,
      modalResourceId: null,
      missingNewPolicy: false,
      // modalNumber: null,
      repositoryResourceId: {
        correspondence: {
          id: null,
        },
        registry: {
          id: null,
        },
      },
      status_registry_value: 0,
      // status_coass: false,
      repository: "insurance_policy",
      isLoading: true,
      // splittato detailFields in 2 oggetti distinti detailFields1 e detailFields2:
      // per inserire nel mezzo la table "statica" coassDetails...
      detailFields: {},
      detailFields1: {
        // campi in comune
        dati_identificativi: {
          header: "Dati identificativi",
          edit: false,
          // replace: false,
          // shortcut_replace: {},
          shortcut: {},
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary("insurer_id", "insurance_policy"),
              value: "insurer.code",
            },
            {
              label: this.getDictionary("number", "insurance_policy"),
              value: "number",
            },
            {
              label: this.getDictionary("code", "risk_branch"), // "risk_branch.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "insurance_risk.risk_branch.code",
            },
            {
              label: this.getDictionary("risk_type", "insurance_risk"), // "risk_type.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "insurance_risk.code",
            },
            {
              label: this.getDictionary("salesman"),
              value: "broker",
              virtual: (detail) => {
                return detail.broker
                  ? this.toInfoData(detail.broker, "broker", null, " ")
                  : "";
              },
            },
            {
              label: this.getDictionary("Collaborazione orizzontale"),
              // label: this.getDictionary("Polizza in collaborazione"),
              value: "collaborator",
              virtual: (detail) => {
                return detail.collaborator
                  ? this.toInfoData(detail.collaborator, "broker", 1)
                  : "";
              },
            },
            {
              label: this.getDictionary("insurance_risk"), // "insurance_risk.default" la label di questa sotto-relazione non arriva nel beForm di repository: uso il default
              value: "",
              virtual: (detail) => {
                return this.getRiskTypeDescription(detail);
              },
            },
          ],
        },
        premi: {
          header: "Premi",
          edit: false,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary("net_premium", "insurance_policy"),
              value: "net_premium",
              type: "currency",
            },
            {
              label: this.getDictionary("tax_premium", "insurance_policy"),
              value: "tax_premium",
              type: "currency",
            },
            {
              label: this.getDictionary("gross_premium", "insurance_policy"),
              value: "gross_premium",
              type: "currency",
            },
          ],
        },
        dati: {
          header: "Dati",
          edit: false,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary(
                "payment_splitting",
                "insurance_policy"
              ),
              value: "payment_splitting.text",
            },
            {
              label: this.getDictionary("issued_at"),
              value: "issued_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("effective_at"),
              value: "effective_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("expired_at"),
              value: "expired_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("covered_at"),
              value: "covered_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary("suspended_at", "insurance_policy"),
              value: "suspended_at",
              type: "datepicker",
            },
            {
              label: this.getDictionary(
                "attribute_REVD_value",
                "insurance_policy"
              ),
              value: "attributables.REVD",
            },
            {
              label: this.getDictionary(
                "attribute_RENW_value",
                "insurance_policy"
              ),
              value: "attributables.RENW",
            },
            {
              label: this.getDictionary(
                "attribute_INDX_value",
                "insurance_policy"
              ),
              value: "attributables.INDX",
            },
            {
              label: this.getDictionary(
                "attribute_CONV_value",
                "insurance_policy"
              ),
              value: "attributables.CONV",
            },
            {
              label: this.getDictionary(
                "attribute_LNOT_value",
                "insurance_policy"
              ),
              value: "attributables.LNOT",
            },
            {
              label: this.getDictionary(
                "attribute_BONA_value",
                "insurance_policy"
              ),
              value: "attributables.BONA",
            },
            {
              label: this.getDictionary("delay_days", "insurance_policy"),
              value: "delay_days",
            },
          ],
        },
        stato_policy: {
          header: "Stato polizza",
          edit: false,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary("status_policy", "insurance_policy"),
              value: "status_policy.text",
            },
            {
              label: this.getDictionary("old_policy", "insurance_policy"),
              value: "number",
              virtual: "OLD_POLICY",
              relation: "old_policy",
              isLink: true,
            },
            {
              label: this.getDictionary("new_policies", "insurance_policy"),
              value: "number",
              virtual: "NEW_POLICIES",
              relation: "new_policies",
              isLink: true,
            },
          ],
        },
      },
      detailFields2: {
        status_registry_1: {
          dati_anagrafici: {
            header: "Dati Anagrafici del contraente",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("attribute_CNAM_value", "registry"),
                value: "registry.attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "registry"),
                value: "registry.attributables.CREG",
              },
            ],
          },
        },
        status_registry_0: {
          dati_anagrafici: {
            header: "Dati Anagrafici del contraente",
            edit: false,
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("attribute_TITL_value", "registry"),
                value: "registry.attributables.TITL",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "registry"),
                value: "registry.attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "registry"),
                value: "registry.attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "registry.attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_MARS_value", "registry"),
                value: "registry.attributables.MARS",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "registry.attributables.PROF",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "registry.attributables.DOB",
              },
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                value: "registry.attributables.POB",
              },
              {
                label: `${this.getDictionary("province")} nascita`,
                value: "registry.attributables.POB",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("country"),
                value: "registry.attributables.POB",
                virtual: "NATION",
              },
              {
                label: this.getDictionary("attribute_ISPL_value", "registry"),
                value: "registry.attributables.ISPL",
              },
              {
                label: `${this.getDictionary("province")} emissione`,
                value: "registry.attributables.ISPL",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("attribute_SEX_value", "registry"),
                value: "registry.attributables.SEX",
              },
            ],
          },
        },
        nascita: {
          header: "Nato a:",
          repository: "registry",
          edit: false,
          fields: [
            {
              label: this.getDictionary("attribute_POB_value", "registry"),
              value: "registry.attributables.POB",
            },
            {
              label: this.getDictionary("province"),
              value: "registry.attributables.POB",
              virtual: "PROVINCE",
            },
            {
              label: this.getDictionary("attribute_NOB_value", "registry"),
              value: "registry.attributables.POB",
              virtual: "NATION",
            },
            {
              label: this.getDictionary("attribute_DOB_value", "registry"),
              value: "registry.attributables.DOB",
            },
            {
              label: this.getDictionary("attribute_NOB2_value", "registry"),
              value: "registry.attributables.NOB",
              virtual: "NATION2",
            },
            {
              label: this.getDictionary("attribute_POB2_value", "registry"),
              value: "registry.attributables.POB2",
            },
          ],
        },
        residenza: {
          header: "Residenza / Sede Legale",
          repository: "correspondence",
          edit: false,
          fields: [
            {
              label: this.getDictionary(
                "attribute_NAME2_value",
                "correspondence"
              ),
              value: "",
              virtual: (detail) => {
                let alternativeAddress;
                let registryFullName;
                if (detail.registry) {
                  const found = detail.registry.correspondences.find(
                    (correspondence) =>
                      correspondence.status_correspondence.value === 0
                  );
                  if (found) {
                    alternativeAddress = found.attributables.NAME2;
                    registryFullName = detail.registry.title;
                  }
                }
                return alternativeAddress
                  ? alternativeAddress
                  : registryFullName;
              },
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attributables.ADDR",
              relation: "registry.correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              relation: "registry.correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY2_value",
                "correspondence"
              ),
              value: "attributables.CITY2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary("province"),
              value: "attributables.CITY",
              relation: "registry.correspondences",
              virtual: "PROVINCE",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ZIPC_value",
                "correspondence"
              ),
              value: "attributables.ZIPC",
              relation: "registry.correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              virtual: "NATION",
              relation: "registry.correspondences",
              status: 0,
            },
            {
              label: `${this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              )} estera`,
              value: "attributables.NOR",
              relation: "registry.correspondences",
              status: 0, // Residenza / Sede Legale
            },
          ],
        },
      },
      oldResourceIdVal: null,
      newResourceIdVal: null,
    };
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  props: {
    resourceId: Number,
    id: {
      type: String,
      default: "replacedPolicyDetailModal",
    },
  },
  watch: {
    resourceId: function (newVal, oldVal) {
      // watch it
      console.log(`Prop "resourceId" changed: , ${newVal}, | was: , ${oldVal}`);
      this.oldResourceIdVal = oldVal;
      this.newResourceIdVal = newVal;
      this.$bvModal.show("replacedPolicyDetailModal");
      this.isLoading = true;
      if (newVal) this.fetch();
    },
  },
  methods: {
    toInfoData,
    getRiskTypeDescription,
    canVerb,
    fetch() {
      if (!this.resourceId) {
        this.missingNewPolicy = true;
        return;
      }
      let filter = `relations/byBroker/byCollaborator/byRegistry/byInsurer/byInsurerParticipant/byInsuranceRisk/byNewPolicy/byOldPolicy`;
      let queryString = `${filter ? filter : ""}`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data.new_policies[0];
          if (!this.details) {
            console.log(
              `New policy non trovato per numero polizza: ${
                data?.number ?? "-"
              }.`
            );
            this.missingNewPolicy = true;
            this.isLoading = false;
            return;
          }
          this.status_registry_value = data.registry?.status_registry.value;
          // this.status_coass = data.insurer_participants?.length;
          // if (data.insurer_participants?.length) {
          //   this.$set(this, "coassDetails", data.insurer_participants);
          // }

          this.repositoryResourceId.registry.id = data.registry?.id;
          // #715
          // se la polizza è a mano ed è in stato perfezionata (1) o sospesa (2),
          // compare il tasto “sostituisci”
          // isImported
          let isImported = true;
          if (this.details.insurer) {
            isImported = this.details.insurer.importer_id ? true : false;
          }
          if (
            this.canVerb("insurance_policies", "store") &&
            !isImported &&
            [1, 2].includes(this.details.status_policy.value)
          ) {
            let s = {
              label: "Sostituisci",
              click: () => {
                this.shortcut(
                  "insurance_policies.replace",
                  this.resourceId,
                  null,
                  null,
                  null,
                  null,
                  null,
                  { details: this.details, beForm: this.beForm },
                  this.$options.name
                );
              },
            };
            this.$set(this.detailFields1.dati_identificativi, "shortcut", s);
          }

          this.isLoading = false;
          this.$emit("number", data.number);
        })
        .catch((error) => {
          this.form = {};
          console.error(error);
        })
        .finally(() => {});
    },
  },
  computed: {
    dtlFlds1() {
      return this.detailFields1;
    },
    dtlFlds2() {
      // mergia i campi di status 0 o 1 con quelli in comune
      let { status_registry_0, status_registry_1, ...rest } =
        this.detailFields2;
      let reg = this.status_registry_value
        ? status_registry_1
        : status_registry_0;
      let r = { ...reg, ...rest };
      // let coa = this.status_coass ? coass : null;
      // let r;
      // if (coa) {
      //   r = { ...rest, coa, ...reg, nascita, residenza };
      // } else {
      //   r = { ...rest, ...reg, nascita, residenza };
      // }
      return r;
    },
  },
  mounted() {
    // usare questo se devo eseguire prima di mostrare
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId !== "replacedPolicyDetailModal") return;
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId !== "replacedPolicyDetailModal") return;
    });
  },
};
</script>
<style scoped>
.tableFixHead {
  overflow: auto;
  height: 200px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  text-align: left;
}
th {
  background: #fff;
}

tr {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
:deep(.modal-dialog .card-body a) {
  color: inherit;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
